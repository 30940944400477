<template>
  <b-row class="flex-grow-1">
    <b-col cols="12">
      <b-row align-v="center" class="mt-5 mb-5 justify-content-between">
        <b-col cols="auto">
          <h1
            class="mb-0 text-primary"
            style="font-size: 22px"
            v-if="currentDoctor.name"
          >
            Replies for {{ currentDoctor.name }}
          </h1>
          <h1 class="mb-0 text-primary" style="font-size: 22px" v-else>
            Replies for Dr. {{ doctorName }}
          </h1>
        </b-col>
        <b-col cols="auto" v-if="!currentDoctor.id">
          <b-button
            pill
            variant="primary"
            class="px-5"
            @click="createNewTemplate"
            >New Template</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-input-group>
            <b-input-group-append>
              <span class="input-group-text">
                <i class="fe fe-search"></i>
              </span>
            </b-input-group-append>
            <b-form-input
              v-model="search"
              class="form-control list-search"
              placeholder="Search"
              @keyup.enter="searchReplies"
            ></b-form-input>
          </b-input-group>
        </b-col>
      </b-row>
      <b-row class="flex-grow-1 mt-4">
        <b-col cols="3">
          <span class="text-muted">Reply</span>
          <b-form-select
            v-model="selectedCategory"
            :options="categoryOptions"
            @input="getCategory"
          >
            <template #first>
              <b-form-select-option value="">
                All Replies
              </b-form-select-option>
              <b-form-select-option value="default">
                Defaults
              </b-form-select-option>
              <b-form-select-option disabled>----</b-form-select-option>
            </template>
          </b-form-select>
          <b-list-group ref="canned-reply-modal-list" class="replyList">
            <b-list-group-item
              v-for="item in defaultReplies"
              :key="item.id"
              @click="setActiveItem(item)"
              :active="item.id == activeItemID"
            >
              {{ item.name }}
            </b-list-group-item>
            <b-list-group-item v-if="savedReplies.length == 0" disabled>
              There are no replies under this category.
            </b-list-group-item>
            <b-list-group-item
              v-for="item in savedReplies"
              :key="item.id"
              @click="setActiveItem(item)"
              :active="item.id == activeItemID"
            >
              {{ item.name }}
            </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col cols="9">
          <div class="d-flex justify-content-between">
            <span class="text-muted"> Content </span>
          </div>
          <b-row>
            <b-col>
              <b-alert
                variant="info"
                :show="replyActiveItemCategory == 'default'"
              >
                You are editing a default reply. To personalize, select a
                different category on the dropdown to the right. Using {patient}
                and {doctor} will automatically insert the patient's name and
                your name for you when you add it in messages.
              </b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <label for="replyActiveItemName">Name</label>
              <b-form-input
                id="replyActiveItemName"
                v-model="activeItemName"
                placeholder="Enter title here..."
                :readonly="Boolean(currentDoctor.id)"
              ></b-form-input>
            </b-col>
            <b-col>
              <label for="replyActiveItemCategory">Category</label>
              <b-form-select
                v-model="replyActiveItemCategory"
                :options="categoryOptions"
                :disabled="Boolean(currentDoctor.id)"
              >
                <template #first>
                  <b-form-select-option value="">
                    No Category
                  </b-form-select-option>
                  <b-form-select-option value="default" disabled
                    >Default</b-form-select-option
                  >
                  <b-form-select-option class="text-primary" value="add"
                    >+ Add New Category</b-form-select-option
                  >
                  <b-form-select-option disabled> ---- </b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
          <b-row v-if="showAddCategoryField" class="mt-4">
            <b-col offset="6" cols="6">
              <label for="addCategoryName">New Category</label>
              <b-form-input
                id="addCategoryName"
                placeholder="Enter new category name here..."
                v-model="newCategoryName"
              ></b-form-input>
            </b-col>
          </b-row>

          <div class="d-flex justify-content-between">
            <label for="replyActiveItemContent" class="mt-4 mb-0"
              >Content</label
            >
            <b-dropdown
              text="Insert"
              variant="link"
              dropleft
              no-caret
              toggle-class="merge-dropdown-button"
              menu-class="merge-dropdown"
            >
              <b-dropdown-item
                v-for="tag of mergeTags"
                :key="tag.tag"
                @click="insertTagAtCursor(tag.tag)"
              >
                {{ tag.tag }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <ComposerEditor
            ref="composer"
            v-model="activeItemContent"
            hide-toolbar
            hide-send-button
          />
        </b-col>
      </b-row>
      <b-row v-if="!currentDoctor.id">
        <!-- FILE UPLOAD -->
        <b-col cols="3"></b-col>
        <b-col cols="9">
          <b-button variant="link" @click="openUpload">
            <icon-paperclip class="tw-w-5 tw-h-5 tw-text-gray-800" />
          </b-button>
          <input
            type="file"
            ref="fileInput"
            style="display: none"
            multiple
            @change="uploadFile"
          />
          <p class="text-muted" v-if="files.length > 0">Attachments</p>
          <ul>
            <li v-for="(file, index) in files" :key="`${index} - ${file.name}`">
              <!-- eslint-disable-next-line prettier/prettier -->
              <span v-if="file.name">{{ file.name }}</span>
              <span v-else
                ><a :href="file.previewURL" target="_blank">{{
                  file.filename
                }}</a></span
              >
              <b-button
                variant="link"
                v-if="file.name"
                @click="deleteAttachment(index)"
              >
                <b-icon-x></b-icon-x>
              </b-button>
              <b-button
                variant="link"
                v-else
                @click="deleteAttachment(index, file.id)"
              >
                <b-icon-x></b-icon-x>
              </b-button>
            </li>
          </ul>
        </b-col>
      </b-row>
      <b-row class="mb-4" v-if="!currentDoctor.id">
        <b-col cols="3"></b-col>
        <b-col cols="9">
          <div class="float-right mt-4">
            <b-button
              variant="outline-primary"
              class="px-5"
              size="lg"
              pill
              @click="showModal"
              :disabled="disableDelete"
            >
              Delete
            </b-button>
            <b-button
              variant="primary"
              class="px-5 ml-4"
              size="lg"
              pill
              @click="saveReply"
              :disabled="disableSave"
            >
              Save
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-col>
    <b-modal
      hide-header
      centered
      hide-footer
      size="s"
      v-model="showDeleteModal"
    >
      <b-row align-h="center" class="p-4">
        <b-col cols="2"></b-col>
        <b-col cols="10">
          <h1 class="heading p-4 mb-0">
            Are you sure you want to delete this template?
          </h1>
        </b-col>
      </b-row>
      <b-row align-h="center" class="p-4">
        <b-col cols="6">
          <b-button
            pill
            block
            @click="deleteActiveItem()"
            variant="outline-primary"
            >Confirm</b-button
          >
          <b-button
            pill
            block
            @click="showDeleteModal = false"
            variant="primary"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
    </b-modal>
    <b-toast
      solid
      toaster="b-toaster-bottom-center"
      id="replyToast"
      :variant="toast.variant"
    >
      {{ toast.message }}
    </b-toast>
  </b-row>
</template>

<script>
import { ReplyService } from "@/services/reply.service";
import IconPaperclip from "@/components/icon/IconPaperclip.vue";
import ComposerEditor from "@corefront/components-v2/Composer/ComposerEditor";

export default {
  components: { IconPaperclip, ComposerEditor },
  name: "CannedReplies",
  props: {
    doctorName: String,
  },
  data() {
    return {
      toast: {
        message: "",
        variant: "",
      },
      search: "",
      defaultReplies: [],
      savedReplies: [],
      activeItemID: "new",
      activeItemName: "",
      activeItemContent: "",
      toastText: "",
      showDeleteModal: false,
      files: [],
      attachmentsToDelete: [],
      apiFileItemNumber: 0,

      // Active item categories
      replyActiveItemCategory: "",

      // * Template Categories
      categories: [],
      selectedCategory: "",
      newCategoryName: "",
      mergeTags: [],
    };
  },
  computed: {
    showAddCategoryField() {
      return this.replyActiveItemCategory == "add";
    },
    disableDelete() {
      return (
        this.activeItemID == "new" || this.replyActiveItemCategory == "default"
      );
    },
    disableSave() {
      return this.replyActiveItemCategory == "default";
    },
    categoryOptions() {
      let categories = [];
      for (let item of this.categories) {
        categories.push({ value: item.id, text: item.name });
      }
      categories.sort((a, b) =>
        a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1
      );
      return categories;
    },

    currentDoctor() {
      return this.$store.state.globals.currentDoctor;
    },
  },
  methods: {
    showToast(message, variant = "success") {
      this.toast.message = message;
      this.toast.variant = variant;
      this.$bvToast.show("replyToast");
    },
    async searchReplies() {
      if (!this.search) {
        this.getReplies();
      } else {
        const res = await ReplyService.searchReplies(this.search);
        if (res.data.length == 1) {
          this.setActiveItem(res.data[0]);
        }
        this.savedReplies = res.data;
        this.savedReplies.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
        await this.$nextTick();
        this.$refs["canned-reply-modal-list"].scrollTop = 0;
      }
    },
    async getReplies() {
      this.getReplyCategories();
      if (this.selectedCategory) {
        this.getCategory();
      } else {
        const res = await ReplyService.listReplies();
        this.savedReplies = res.data;
        this.savedReplies.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
      }
    },
    async getReplyCategories() {
      const { data } = await ReplyService.listReplyCategory();
      this.categories = data;
    },
    async getCategory() {
      if (this.selectedCategory == "") {
        this.getReplies();
      } else {
        const { data } = await ReplyService.searchReplyByCategory(
          this.selectedCategory
        );
        this.savedReplies = data;
        this.savedReplies.sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
        );
      }
    },
    async getMergeTags() {
      const { data } = await ReplyService.getMergeTags();
      this.mergeTags = data;
    },
    async setActiveItem(item) {
      if (item.default) {
        this.activeItemID = item.id;
        this.activeItemName = item.name;
        this.activeItemContent = item.content;
        this.replyActiveItemCategory = "default";
        return;
      }

      this.activeItemID = item.id;
      // this.activeItemName = item.name;
      // this.activeItemContent = item.content;
      const res = await ReplyService.getReply(item.id);
      this.activeItemName = res.data.name;
      this.activeItemContent = res.data.content;
      if (res.data.messagetemplatecategoryid) {
        this.replyActiveItemCategory = res.data.messagetemplatecategoryid;
      } else {
        this.replyActiveItemCategory = "";
      }
      this.attachmentsToDelete = [];
      if (res.data.attachment) {
        this.apiFileItemNumber = res.data.attachment.length;
        this.files = res.data.attachment;
      } else {
        this.files = [];
      }
    },
    createNewTemplate() {
      this.activeItemID = "new";
      this.activeItemName = "";
      this.replyActiveItemCategory = "";
      this.activeItemContent = "";
    },
    async saveReply() {
      let params = {
        name: this.activeItemName,
        content: this.activeItemContent,
      };

      // Adds new Category
      if (this.replyActiveItemCategory == "add") {
        const newCategory = {
          name: this.newCategoryName,
        };
        try {
          const newCategoryRequest = await ReplyService.addReplyCategory(
            newCategory
          );
          this.replyActiveItemCategory = newCategoryRequest.data.id;
          params.messagetemplatecategoryid = this.replyActiveItemCategory;
        } catch (error) {
          this.showToast(error, "warning");
        }
      } else if (this.replyActiveItemCategory == "") {
        params.messagetemplatecategoryid = null;
      } else {
        params.messagetemplatecategoryid = this.replyActiveItemCategory;
      }

      if (this.activeItemID == "new" || this.activeItemID.includes("default")) {
        if (this.files.length > 0) {
          let mForm = new FormData();
          mForm.append("name", this.activeItemName);
          mForm.append("content", this.activeItemContent);

          for (let i of Object.keys(this.files)) {
            if (this.files[i] instanceof File) {
              mForm.append("attachment", this.files[i]);
            }
          }
          // Upload file
          const res = await ReplyService.addTemplateWithAttachment(mForm);
          if (res.data) {
            this.activeItemID = res.data.id;

            try {
              // Link file to category
              await ReplyService.updateMessageTemplate(res.data.id, params);
            } catch (err) {
              this.showToast(err, "warning");
            }
            this.setActiveItem(res.data);
            this.getReplies();
            this.showToast("New reply saved successfully!");
          }
        } else {
          const res = await ReplyService.addTemplate(params);
          if (res.data) {
            this.activeItemID = res.data.id;
            this.setActiveItem(res.data);
            this.getReplies();
            this.showToast("New reply saved successfully!");
          }
        }
      } else {
        // Delete files
        if (this.attachmentsToDelete.length > 0) {
          for (let attachmentID of this.attachmentsToDelete) {
            const res = await ReplyService.removeAttachment(
              this.activeItemID,
              attachmentID
            );
          }
        }

        // Attach files
        if (this.files.length > 0) {
          const mForm = new FormData();
          // Add new files to message
          for (let i of Object.keys(this.files)) {
            if (this.files[i] instanceof File) {
              mForm.append("attachment", this.files[i]);
            }
          }
          try {
            const res = await ReplyService.addAttachmentToTemplate(
              this.activeItemID,
              mForm
            );
          } catch (err) {}
        }

        const res = await ReplyService.updateMessageTemplate(
          this.activeItemID,
          params
        );
        if (res.data) {
          this.getReplies();
          this.setActiveItem({ id: this.activeItemID });
          this.showToast("Reply saved successfully!");
        }
      }
    },
    showModal() {
      this.showDeleteModal = true;
    },
    async deleteActiveItem() {
      const res = await ReplyService.deleteMessageTemplate(this.activeItemID);
      if (res.data) {
        this.getReplies();
        this.showDeleteModal = false;
        this.showToast("Reply deleted successfully!");
      }
    },
    openUpload() {
      this.$refs.fileInput.click();
    },
    uploadFile() {
      let uploadedFiles = this.$refs.fileInput.files;
      for (let file of uploadedFiles) {
        file.url = URL.createObjectURL(file);
        this.files.push(file);
      }
    },
    deleteAttachment(index, id = "") {
      this.files.splice(index, 1);
      if (id) {
        this.attachmentsToDelete.push(id);
      }
    },
    insertTagAtCursor(tag) {
      const editor = this.$refs.composer.editor;
      const startPos = editor.state.selection.from;
      const endPos = editor.state.selection.to;
      this.activeItemContent =
        str.substring(0, startPos) +
        `{${tag}} ` +
        str.substring(endPos, str.length);

      editor.commands.insertContentAt({ start: startPos, end: endPos }, text);
    },
  },
  async mounted() {
    window.analytics.page();
    // Get others
    await this.getReplies();
    await this.getReplyCategories();
    await this.getMergeTags();
    // Get default replies
    this.defaultReplies.unshift(...this.$store.state.reply.defaultReplies);
  },
};
</script>

<style lang="scss" scoped>
.replyList {
  height: 60vh;
  overflow: auto;
}

.merge-dropdown {
  width: 420px !important;
  max-height: 60vh;
  overflow: auto;
  margin-right: 10px !important;
}

.merge-dropdown-button {
  text-transform: capitalize !important;
  letter-spacing: 1px !important;
  margin-top: 20px;
  padding: 0 0 0 0 !important;
  height: 40px !important;
}

/* Editor height */
::v-deep .composer-editor {
  min-height: 150px !important;
  max-height: 250px !important;
}
</style>
